<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-23 13:51:35
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-09-10 22:20:53
 * @Description  : 创建规则模板选择
-->
<template>
  <div class="container">
    <div class="title font-heavy">{{ $t("new_alert_rules") }}</div>
    <div>{{ $t("alert_tip_key_for_create") }}</div>
    <div class="tip">
      <div style="font-weight: 500">{{ $t("choose_template") }}</div>
      <span style="margin: 0 20px">{{ $t("or") }}</span>
      <span class="without-template" @click="withoutTemp()">{{
        $t("continue_without_a_template")
      }}</span>
    </div>
    <div class="card-box">
      <el-card
        class="card-style"
        v-for="(item, index) in templateList"
        :key="index"
        :value="item.name"
      >
        <div>{{ $t(item.name) }}</div>
        <div>{{ item.description || "-" }}</div>
        <el-button type="primary" @click="withTemp(item.sceneId)">{{
          $t("choose")
        }}</el-button>
      </el-card>
    </div>
  </div>
</template>

<script>
import { getTemplateListApi } from "@/api/api.js";

export default {
  name: "CreateScene",
  data() {
    return {
      isTemp: true,
      templateList: [],
      // unit: "C",
    };
  },
  methods: {
    withoutTemp() {
      this.goPages("/createAlertWithoutTemplate", {});
    },
    withTemp(id) {
      console.log("id: ", id);
      this.goPages("/createAlertWithTemplate", {
        id: id,
      });
    },
    getTemplateList() {
      getTemplateListApi({
        unit: this.setSelectedTempType == "°C" ? "C" : "F",
      }).then((r) => {
        console.log("=====", r);
        this.templateList = r.data;
        // const keyTemplates = {
        //   "Room temperature": "key_template_room",
        //   Refrigerated: "key_template_refrigerator",
        //   Freezer: "key_template_frezer",
        // };

        // this.templateList.forEach((item) => {
        //   for (const [templateName, templateKey] of Object.entries(
        //     keyTemplates
        //   )) {
        //     if (item.name === templateName) {
        //       item.name = templateKey;
        //       break;
        //     }
        //   }
        // });
        console.log("this.templateList: ", this.templateList);
      });
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
  },

  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },

    setSelectedTempType(newVal) {
      console.log("newVal: ", newVal);
      // this.unit = newVal == "°C" ? "C" : "F";
      this.getTemplateList();
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;

    this.getTemplateList();
  },
  created() {},
};
</script>

<style scoped lang="less">
.container {
  // padding: 20px;
  padding-top: 16px;
  margin-left: 38px;
  .title {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .el-button--primary {
    background: #1a72f4;
    border-color: #1a72f4;
    width: 100%;
    margin-top: 10px;
  }
  .card-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;

    .card-style {
      width: 30%;
      margin: 10px;
    }
  }
  .tip {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    margin: 10px 0;
    span {
      color: #1a72f4;
    }
  }
  .without-template {
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
